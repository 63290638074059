import type { EskerInvoiceInterface, Invoice, InvoiceLink } from './useEskerInvoice';
import type { Ref } from '@nuxtjs/composition-api';
import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';

/**
 * Manager Invoice API
 */
export function useEskerInvoice(): EskerInvoiceInterface {
  const loading: Ref<boolean> = ref(false);
  const { app } = useContext();
  const context = app.$vsf;

  const getInvoice = async (customerCode: string | null, orderId: string | null, invoiceId: string | null): Promise<InvoiceLink> => {
    loading.value = true;
    Logger.debug('[Magento] before getting invoice');
    let results = null;

    try {
      const { data } = await context.$magento.api.getEskerInvoice(customerCode, orderId, invoiceId);
      results = data ?? null;
      Logger.debug('[Magento] get invoice:', results);
    } catch (err) {
      Logger.error('Magento] get invoice', err);
    } finally {
      loading.value = false;
    }

    return results;
  }

  const listInvoices = async (): Promise<[Invoice]> => {
    loading.value = true;
    Logger.debug('[Magento] before listing invoices');
    let results = [];

    try {
      const { data } = await context.$magento.api.listInvoices();
      results = data ?? [];
      Logger.debug('[Magento] list invoices:', results);
    } catch (err) {
      Logger.error('Magento] list invoices', err);
    } finally {
      loading.value = false;
    }

    return results;
  }

  return { getInvoice, listInvoices };
}

export * from './useEskerInvoice';
export default useEskerInvoice;
