import defaultI18N from "../../default/lang/fr"

export default {
  ...defaultI18N,
  "Customer Cromology": "de client Tollens",
  "Does your society already have a client account ?": "Votre société a-t-elle déjà un compte client Tollens ?",
  "No, i don't have an account": "Non, je n'ai pas de compte client Tollens mais je souhaite ouvrir un accès web professionnel",
  "Yes, I am already client": "Oui, je suis déjà client Tollens et je souhaite avoir un accès web.",
  "home_meta_title": "Peinture professionnelle intérieur, façade et technique, blanc et couleurs",
  "home_meta_description": "Fabrication française. 170 magasins. Choix infini de teintes, livraison, facilités de paiement, collecte gratuite des déchets, click&collect 3H. Outillage, revêtements murs et sols.",
  "site_name": "Tollens",
  "contact_meta_description": "Contact - Conseils et expertise Tollens pour les particuliers et les professionnels - Fabricant et distributeur de peintures, revêtements sol et mur et solutions d'isolation ITE.",
  "category_meta_description": "Fabrication française. 170 magasins. Choix infini de teintes, livraison, facilités de paiement, collecte gratuite des déchets, click&collect 3H. Outillage, revêtements murs et sols.",
};
