<template>
  <div class="mega-menu-navbar desktop-only">
		<!-- Category type 0 = catégories produits -->
    <HeaderNavigationItem
      v-for="(category, index) in categoryTree"
      v-if="category && ((category.product_count > 0 && (category.category_type === 0)) || category.category_type === 1 )"
      :key="index"
      :cattype="category.category_type"
      :image="category.image"
      :image-link="category.custom_url"
      :index="index"
      :items="category.children"
      :label="category.name"
      :link="localePath(getAgnosticCatLink(category))"
    />
		<!-- Category type 1 = catégories pages CMS -->
		<HeaderNavigationItem
      v-for="(category, index) in categoryTreeAdditional"
      v-if="category.name && category.category_type === 1"
      :key="'additional_'+index"
      :cattype="category.category_type"
      :image="category.image"
      :image-link="category.custom_url"
      :index="index"
      :items="category.children"
      :label="category.name"
      :link="localePath(getAgnosticCatLink(category))"
    />
  </div>
</template>
<script>
import { useUiHelpers } from '~/composables';
import HeaderNavigationItem from '~/components/Header/Navigation/HeaderNavigationItem.vue';

export default {
  name: 'HeaderNavigationItems',
  components: {
    HeaderNavigationItem,
  },
  props: {
    categoryTree: {
      type: Array,
      default() {
        return []
      }
    },
    categoryTreeAdditional: {
      type: Array,
      default() {
        return []
      }
    },
    link: {
      type: [String, Object],
      default: '',
    },
  },
  setup() {
    const { getCatLink: getAgnosticCatLink } = useUiHelpers();

    return {
      getAgnosticCatLink,
    }
  }
};
</script>
